var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "wrap"
  }, [_c('div', {
    staticClass: "gift-info"
  }, [_c('div', {
    staticClass: "tit"
  }, [_vm._v("兑换礼品")]), _c('div', {
    staticClass: "list"
  }, [_c('div', {
    staticClass: "li"
  }, [_c('div', {
    staticClass: "cover"
  }, [_c('van-image', {
    attrs: {
      "width": "100%",
      "height": "100%",
      "fit": "cover",
      "src": _vm.goodData.cover
    }
  })], 1), _c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.goodData.name))])])])]), _c('div', {
    staticClass: "tip"
  }, [_vm._v("请您放心填写本页信息，我们会严格保护您的隐私，本次所填写的信息仅用于礼品派送。")]), _c('van-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('van-field', {
    attrs: {
      "name": "用户名",
      "label": "收货人",
      "placeholder": "请输入收货人",
      "input-align": "right",
      "rules": [{
        required: true,
        message: '请输入收货人'
      }]
    },
    model: {
      value: _vm.formData.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  }), _c('van-field', {
    attrs: {
      "name": "phoneValidator",
      "label": "手机号码",
      "placeholder": "请输入收货人手机号码",
      "input-align": "right",
      "rules": [{
        required: true,
        message: '请输入收货人手机号码'
      }, {
        validator: _vm.phoneValidator,
        message: '请输入11位手机号码'
      }]
    },
    model: {
      value: _vm.formData.phone,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "phone", $$v);
      },
      expression: "formData.phone"
    }
  }), _c('div', {
    staticClass: "tip",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("请填写常用手机号，以便快递员能联系到您。")]), _c('van-field', {
    attrs: {
      "readonly": "",
      "clickable": "",
      "name": "area",
      "value": _vm.formData.city,
      "label": "收货地区",
      "placeholder": "请选择收货地区",
      "input-align": "right",
      "rules": [{
        required: true,
        message: '请选择收货地区'
      }]
    },
    on: {
      "click": function ($event) {
        _vm.showArea = true;
      }
    }
  }), _c('van-field', {
    attrs: {
      "name": "用户名",
      "label": "街道、门牌",
      "placeholder": "请输入具体门牌号",
      "input-align": "right",
      "rules": [{
        required: true,
        message: '请输入具体门牌号'
      }]
    },
    model: {
      value: _vm.formData.address,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "address", $$v);
      },
      expression: "formData.address"
    }
  }), _c('div', {
    staticClass: "tip",
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v("请填写常住地址，以便快递员能联系到您。")]), _c('foot-bar-box-comp', [_c('div', {
    staticClass: "foot-div"
  }, [_c('div', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm.goodData.integral ? `${_vm.goodData.integral}积分` : '') + _vm._s(_vm.goodData.integral && _vm.goodData.price ? '+' : '') + _vm._s(_vm.goodData.price ? `${_vm.goodData.price}元` : ''))]), _c('div', {
    staticClass: "button"
  }, [_c('van-button', {
    attrs: {
      "block": "",
      "type": "info",
      "color": "#C70C0C",
      "loading": _vm.buttonLoad,
      "loading-size": "24px",
      "loading-text": "加载中...",
      "native-type": "submit"
    }
  }, [_vm._v(" 领取 ")])], 1)])])], 1), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showArea,
      callback: function ($$v) {
        _vm.showArea = $$v;
      },
      expression: "showArea"
    }
  }, [_c('van-area', {
    attrs: {
      "area-list": _vm.areaList
    },
    on: {
      "confirm": _vm.onCityConfirm,
      "cancel": function ($event) {
        _vm.showArea = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };